var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"98%","icon":"mdi-cellphone-dock","color":"primary","title":_vm.$t(_vm.entity + '.TITLE'),"text":_vm.$t(_vm.entity + '.SUBTITLE')}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"name","show-expand":""},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.getCleanleadsChunks},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogNewCampaign = true}}},[_vm._v("Nueva campaña")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"mt-2"},[_c('b',[_vm._v("Tandas de "+_vm._s(item.chunkSize)+" usuarios - Total: "+_vm._s(item.chunkPages)+" "+_vm._s(item.chunkPages == 1 ? "tanda" : "tandas"))]),_c('v-list',_vm._l((item.chunks),function(chunk,chunkIndex){return _c('v-list-item',{key:item._id + chunkIndex},[_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.sendChunkCampaign(item, chunk, chunkIndex)}}},on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar "+_vm._s("Tanda " + (chunkIndex + 1)))])])],1),_c('v-list-item-content',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getChunkDetail(item, chunk, chunkIndex)}}},[_vm._v(" "+_vm._s("Tanda " + (chunkIndex + 1))+" ")])],1)}),1)],1)])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(_vm._s(_vm.$t(_vm.entity + ".NO_DATA")))])]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.updatedAt)))]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-btn',{attrs:{"color":"primary","small":""}},[_vm._v("Visitar")])],1)]}},{key:"item.attributes",fn:function(ref){
var item = ref.item;
return _vm._l((item.attributes),function(attribute,attIndex){return _c('ul',{key:attIndex},[_c('li',[_c('b',[_vm._v(_vm._s(attribute.name)+": ")]),_vm._v(_vm._s(attribute.options.join(","))+" ")])])})}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(category,cattIndex){return _c('ul',{key:cattIndex},[_c('li',[_vm._v(_vm._s(category.name))])])})}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'Enviado')?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Enviado")]):_c('v-chip',{attrs:{"color":"warning"}},[_vm._v("Pendiente")])]}},{key:"item.scheduleDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.scheduleDateTime))+" ")]}},{key:"item.range",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.segmentCount)+" "+_vm._s(item.segment && item.segment.type === "static" ? "(Estático)" : "")+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s((item.segmentCount * 0.0757).toFixed(2))+" ")]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialogNewCampaign),callback:function ($$v) {_vm.dialogNewCampaign=$$v},expression:"dialogNewCampaign"}},[_c('v-card',[_c('MarketingCampaignsForm',{on:{"onSave":function($event){_vm.dialogNewCampaign = false;
          _vm.initialize();}}})],1)],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.isShowingDetail),callback:function ($$v) {_vm.isShowingDetail=$$v},expression:"isShowingDetail"}},[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Detalle")])]),_c('v-divider',{staticClass:"mb-3"}),(!_vm.chunkDetail)?_c('h3',[_vm._v("Cargando datos...")]):_c('ul',_vm._l((_vm.chunkDetail.leads),function(lead,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(lead.telefono)+" ")])}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }